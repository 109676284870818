import React from "react"
import css from "@emotion/css"



export const VideoPlayer =({ id, name }) => {
    return (
        <div className="wistia_responsive_padding" css={
            css`
                padding-bottom:56.25%;
                padding-top:1px;
                position:relative;
                margin: 0 auto;`
            }>
          <div className="wistia_responsive_wrapper" css={
              css`
              height:100%;
              left:0;
              position:absolute;
              top:0;
              width:100%;`}>
            <iframe
              src={`//fast.wistia.net/embed/iframe/${id}`}
              title={name}
              allowtransparency="true"
              frameBorder="0"
              scrolling="no"
              className="wistia_embed"
              name="wistia_embed"
              allowFullScreen={true}
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              width="100%"
              height="100%"
              fullscreenbutton="true"
            />
          </div>
        </div>
    )
}
