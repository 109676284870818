import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/layouts/header";
import css from "@emotion/css";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";

import { VideoPlayer } from "../components/VideoPlayer";
import { HeroBanner } from "../components/HeroBanner";
import { PrimaryLink } from "../components/PrimaryButton";
function Index({ data }) {
  const {
    contentfulAboutPage: {
      pageTitle,
      metaDescription,
      heroBanner: { title, heroImage },
      aboutContentTop,
      wistiaVideoContent: { name, hashed_id },
      aboutContentBottom,
      awardsContent,
      buttonText,
      buttonLink,
      gallery
    }
  } = data;
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="Ratio, fintech, finance, software, about, Ratio's story"
        />
      </Helmet>
      <div>
        <div className="hero bg-gradient">
          {" "}
          <Header />
          <div id="heroBanner" className="container section-py ">
            <HeroBanner version="left" heroImage={heroImage} title={title} />
          </div>
        </div>
      </div>

      <TextSection className="section-py" html={aboutContentTop.md.html} />
      <div
        css={css`
          background-color: #e7eaea;
        `}
      >

      <div className="section-py" css={css`padding-top: 0; background-color: #ffffff;`}>
        <div className="container ">
          <VideoPlayer
            id={hashed_id}
            name={name}
          />
        </div>
      </div>

      <TextSection
          className="section-py"
          html={aboutContentBottom.md.html}
          buttonText={buttonText}
          buttonLink={buttonLink}
        />
      </div>
      <TextSection
        className="section-py"
        aCSS={`

              h3 {
                font-weight: 600;
                strong {
                  color: var(--primary);
                }
                color: #94A1BA;
              }
              hr {
                display: block;
                clear: bottom;
              }
              p {
                column-span:1;
              }
        `}
        html={awardsContent.md.html.replace("<hr>", `<hr style="opacity:0;">`)}
      />
      <div id="gallery">
        <div className="row no-gutters">
          {gallery.map(item => {
            return (
              <div key={Math.random()} className="col-md-3 col-sm-4 col-6">
                <GatsbyImage fluid={item.fluid} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Index;

export const query = graphql`
  {
    contentfulAboutPage {
      pageTitle: title
      metaDescription
      heroBanner {
        title
        heroImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
      aboutContentTop {
        md: childMarkdownRemark {
          html
        }
      }
      wistiaVideoContent{
          thumbnail
          name
          hashed_id
      }
      aboutContentBottom {
        md: childMarkdownRemark {
          html
        }
      }
      buttonLink
      buttonText
      awardsContent {
        md: childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        fluid(maxHeight: 600, maxWidth: 600, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

const TextSection = ({ html, aCSS, className, buttonText, buttonLink }) => (
  <section className={className}>
    <div
      className="container "
      css={css`
        ${aCSS}
      `}
    >
      {" "}
      <div className="row">
        <div className="col-12 col-md-10 offset-0 offset-md-1">
          <div
            css={css`
              column-count: 1;
              column-fill: balance;
              column-gap: 60px;
              /* pointer-events: none; */
              position: relative;

              @media (min-width: 992px) {
                margin-top: 4rem;
                column-count: 2;
                h1,
                h2 {
                  position: absolute;
                  top: -4rem;
                }
              }
              h1,
              h2 {
                font-family: "futura-pt", sans-serif;
                font-weight: 600;

                margin-bottom: 2rem;
                color: var(--primary);
              }
              h2 {
                font-size: 36px;
              }
              h3 {
                font-size: 28px;
              }
            `}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: html
              }}
            />
            {buttonLink && buttonText && (
              <div>
                <PrimaryLink
                  className="btn btn-lg text-white bg-gradient"
                  to={buttonLink.endsWith("/") ? buttonLink : `${buttonLink}/`}
                  text={buttonText}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
);
